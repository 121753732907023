import { TextField, Box } from '@mui/material';
import clsx from 'clsx';

import { ReactComponent as CheckMarkCircle } from 'assets/icons/checkmark-circle.svg';
import { useLocale } from 'hooks/useLocale/useLocale';

import { useStyles } from './Input.styles';
import { InputProps } from './Input.types';

export const Input = ({
  success,
  error,
  warning,
  className,
  helperText,
  boldLabel,
  required,
  label,
  optional,
  filter,
  name,
  register,
  ...props
}: InputProps) => {
  const styles = useStyles({ success, warning, boldLabel, filter });
  const inputStyles = clsx(styles.root, className);
  const { formatMessage } = useLocale();
  const getLabel = () => {
    if (!label) return undefined;
    if (optional) {
      return `${label} ${formatMessage({ id: 'global.fields.optional' })}`;
    }
    return label;
  };

  return (
    <TextField
      className={inputStyles}
      InputLabelProps={{ shrink: true }}
      variant="outlined"
      required={required}
      label={getLabel()}
      helperText={
        helperText && (
          <>
            {(success || error || warning) && (
              <Box component="span" sx={{ display: 'flex', alignItems: 'center', mr: 1 }}>
                {success && <CheckMarkCircle className={styles.checkIcon} />}
              </Box>
            )}
            {helperText}
          </>
        )
      }
      {...(register && register(name || ''))}
      error={error}
      {...props}
    />
  );
};
