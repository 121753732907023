import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { LoaderProps } from './Loader.types';

export const useStyles = makeStyles<Theme, LoaderProps>(() => ({
  loader: {
    width: ({ isMaxSize, isFullWidth }) => (isMaxSize ? '100vw' : isFullWidth ? '100%' : 'unset'),
    height: ({ isMaxSize, isFullHeight }) => (isMaxSize ? '100vh' : isFullHeight ? '100%' : 'unset'),
    position: ({ isMaxSize }) => (isMaxSize ? 'fixed' : 'unset'),
    zIndex: 99999,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));
