import Autocomplete from '@mui/material/Autocomplete';
import moment from 'moment';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useCreateSendEmailRemainder } from 'api/institutions/institutions';
import { EventState, InstitutionDetails } from 'api/institutions/institutions.types';
import { ReactComponent as Checked } from 'assets/icons/checkmark-circle.svg';
import groupImg from 'assets/images/group-2.jpg';
import stageClipLogo from 'assets/images/stage-clip-logo.png';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppRoute } from 'routing/AppRoute.enum';
import { Button, Card, Input, Typography } from 'ui/atoms';

import { useStyles } from './MultiEvent.style';
import { MultiEventProps } from './MultiEvent.types';

export const MultiEvent = ({ institutionData, email }: MultiEventProps) => {
  const styles = useStyles();
  const { formatMessage } = useLocale();
  const navigate = useNavigate();
  const [eventDetails, setEventDetails] = useState<InstitutionDetails | null>(institutionData[0]);

  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleSelect = (event: React.ChangeEvent<{}>, value: InstitutionDetails | null) => {
    setEventDetails(value);
  };

  const openInNewTab = (url: string) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const { mutate: saveInstituitionDetails } = useCreateSendEmailRemainder({});

  const sendEmail = async (id: string, email?: string) => {
    await saveInstituitionDetails({
      body: {
        participantId: id,
        email: email,
      },
    });
  };

  return (
    <div className={styles.root}>
      <div className={styles.cardContainer}>
        <div className={styles.stageClipLogo}>
          <img src={stageClipLogo} alt="Logo" />
        </div>
        <div className={styles.content}>Find My Clip</div>
        <Card className={styles.card}>
          <div className={styles.icon}>
            <Checked />
          </div>

          <Typography className={styles.emailTitle}>{formatMessage({ id: 'clip.sent.successful.title' })}</Typography>

          <div>
            <Autocomplete
              size="small"
              className={styles.institutionsDropdown}
              id="combo-box-demo"
              options={institutionData}
              getOptionLabel={(option) =>
                `${option.eventData.name} - ${moment(option.eventData.startDateOnly).format('DD/MMM/YYYY')} at ${
                  option.eventData.startTimeOnly
                }`
              }
              onChange={handleSelect}
              defaultValue={institutionData[0]}
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.id}>
                    {option.eventData.name} - {moment(option.eventData.startDateOnly).format('DD/MMM/YYYY')} at{' '}
                    {option.eventData.startTimeOnly}
                  </li>
                );
              }}
              sx={{ width: 350 }}
              renderInput={(params) => <Input {...params} label="Select event" />}
              placeholder="Enter Institution Name"
              disableClearable
              disableListWrap
            />
          </div>
          <div>
            <Button
              onClick={() => {
                if (eventDetails?.eventData.eventIsArchived) {
                  navigate(AppRoute.clipDeleted);
                } else if (
                  !eventDetails?.clipSiteUrl &&
                  eventDetails?.clipSearchEnable &&
                  eventDetails.eventData.eventIsArchived
                ) {
                  navigate(AppRoute.clipUserExist);
                } else if (eventDetails?.eventData.state === EventState.ClippingInProgress) {
                  navigate(AppRoute.clipInProgress, { state: { data: eventDetails, email: email } });
                } else if (eventDetails?.eventData.state === EventState.WaitingForAssets) {
                  navigate(AppRoute.clipWaitingAssets, { state: { data: eventDetails, email: email } });
                } else if (eventDetails?.eventData.state === EventState.ReviewNeeded) {
                  navigate(AppRoute.noClipUserExist);
                } else if (eventDetails?.eventData.state === EventState.ReadyToClip) {
                  navigate(AppRoute.noClipUserExist);
                } else if (
                  eventDetails?.isClipSiteUrlInclude &&
                  eventDetails?.clipSearchEnable &&
                  !eventDetails.eventData.eventIsArchived
                ) {
                  openInNewTab(`${eventDetails.clipSiteUrl}`);
                  sendEmail(eventDetails.id);
                } else if (
                  eventDetails?.isClipSiteUrlInclude &&
                  eventDetails?.clipSearchEnable === false &&
                  !eventDetails?.eventData.eventIsArchived
                ) {
                  navigate(AppRoute.clipCloseSuccess, { state: { data: eventDetails, email: email } });
                  sendEmail(eventDetails.id);
                } else {
                  navigate(AppRoute.noClipUserExist);
                }
              }}
              disabled={!eventDetails}
              className={styles.button}
            >
              {formatMessage({ id: 'global.actions.confirm' })}
            </Button>
          </div>
        </Card>
      </div>

      <div className={styles.institutionContainer}>
        <Typography className={styles.clientText}>{formatMessage({ id: 'find.clips.institutions.title' })}</Typography>
        <div className={styles.imageContainer}>
          <img src={groupImg} alt="HeaderImage" className={styles.images} />
        </div>
      </div>
    </div>
  );
};
