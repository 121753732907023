import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>(({ palette, transitions, spacing }) => {
  return {
    switchContainer: {
      width: `${spacing(5.25)} !important`,
      height: `${spacing(3.25)} !important`,
      padding: `${spacing(0)} !important`,
      '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
          transform: `translateX(${spacing(2)})`,
          color: '#fff',
          '& + .MuiSwitch-track': {
            backgroundColor: palette.primary.main,
            opacity: 1,
            border: 0,
          },
          '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 0.5,
          },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
          color: palette.primary.main,
          border: `${spacing(0.75)} solid ${palette.primary.contrastText}`,
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
          color: palette.mode === 'light' ? palette.grey[100] : palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: palette.mode === 'light' ? 0.7 : 0.3,
        },
      },
      '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: spacing(2.75),
        height: spacing(2.75),
      },
      '& .MuiSwitch-track': {
        borderRadius: spacing(1.625),
        backgroundColor: palette.mode === 'light' ? palette.gray.main : palette.darkGray.dark,
        opacity: 1,
        transition: transitions.create(['background-color'], {
          duration: 500,
        }),
      },
    },
  };
});
