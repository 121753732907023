import { useMediaQuery, useTheme } from '@mui/material';
import { useEffect, useState, useRef } from 'react';
// import { useNavigate } from 'react-router-dom';

// import { Sidebar } from 'app/layout/dashboard/sidebar/Sidebar';
import { GlobalLayout } from 'app/layout/globalLayout/GlobalLayout';
// import { AppRoute } from 'routing/AppRoute.enum';

import { useStyles } from './Dashboard.styles';
import { DashboardLayoutProps } from './Dashboard.types';
import { TopBar } from './topBar/TopBar';

export const DashboardLayout = ({ children }: DashboardLayoutProps) => {
  // const navigate = useNavigate();
  // it's necessary because we always want to display the sidebar on desktop devices but on tablet and mobile on-demand
  const isDesktop = useMediaQuery(useTheme().breakpoints.up('sm'));
  const [isSidebarDisplayed, setIsSidebarDisplayed] = useState(false);
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(false);
  const isInitialMount = useRef(true);

  const styles = useStyles({
    isSidebarExpanded,
    isSidebarDisplayed,
  });

  // const handleLogoutConfirmation = () => {
  //   navigate(AppRoute.login);
  // };

  // const handleSidebarExpanded = () => setIsSidebarExpanded((previousIsExpanded) => !previousIsExpanded);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return;
    }

    if (!isDesktop && isSidebarDisplayed) {
      setIsSidebarExpanded(true);
    }
  }, [isDesktop, isSidebarDisplayed]);

  return (
    <GlobalLayout>
      <div className={styles.wrapper}>
        <div className={styles.sidebarContainer}>
          <TopBar isSidebarDisplayed={isSidebarDisplayed} setIsSidebarDisplayed={setIsSidebarDisplayed} />
        </div>

        <div className={styles.content}>{children}</div>
      </div>
    </GlobalLayout>
  );
};
