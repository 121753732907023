import { useLocation, useNavigate } from 'react-router-dom';

import { ReactComponent as EmailSent } from 'assets/icons/email-setting.svg';
import groupImg from 'assets/images/group-2.jpg';
import stageClipLogo from 'assets/images/stage-clip-logo.png';
import { useLocale } from 'hooks/useLocale/useLocale';
import { Card, Typography } from 'ui/atoms';

import { useStyles } from './ClipCloseSuccess.styles';

export const ClipCloseSuccess = () => {
  const styles = useStyles();
  const navigate = useNavigate();

  const { state } = useLocation();
  const { formatMessage } = useLocale();

  const obscureEmail = (email: string) => {
    const parts = email.split('@');
    const username = parts[0];
    const domain = parts[1];
    const obscuredUsername = username.charAt(0) + username.charAt(1) + '▪▪▪▪';
    const obscuredDomain = domain.charAt(0) + '▪▪▪▪' + domain.slice(-3);

    return `${obscuredUsername}@${obscuredDomain}`;
  };

  return (
    <div className={styles.root}>
      <div className={styles.cardContainer}>
        <div className={styles.stageClipLogo}>
          <img src={stageClipLogo} alt="Logo" onClick={() => navigate('/')} />
        </div>
        <div className={styles.content}>Find My Clip</div>
        <Card className={styles.card}>
          <div className={styles.icon}>
            <EmailSent />
          </div>
          <Typography className={styles.systemStatus}>Sent</Typography>

          <Typography className={styles.emailTitle}>{state.data[0]?.fullName}</Typography>

          <Typography className={styles.emailDescription}>
            {` Stay tuned for your special moment which will be sent shortly by email to ${obscureEmail(state.email)}.
            Be sure to check your junk mail too! `}
            <span className={styles.overrideColor}> 🌟🎥 </span>
          </Typography>
          <div>
            <Typography className={styles.differentEmail}>
              {formatMessage({ id: 'clip.not.available.different.email' })}
              <br />
              {formatMessage({ id: 'clip.raise.ticket' })}
            </Typography>
          </div>
          <div>
            <a
              className={styles.linkTicket}
              href="https://stageclip.com/student-support/"
              target="_blank"
              rel="noreferrer"
            >
              here
            </a>
          </div>
        </Card>
      </div>

      <div className={styles.institutionContainer}>
        <Typography className={styles.clientText}>{formatMessage({ id: 'find.clips.institutions.title' })}</Typography>
        <div className={styles.imageContainer}>
          <img src={groupImg} alt="HeaderImage" className={styles.images} />
        </div>
      </div>
    </div>
  );
};
