import { useCreateSendEmailRemainder } from 'api/institutions/institutions';
import { ReactComponent as Checked } from 'assets/icons/checkmark-circle.svg';
import groupImg from 'assets/images/group-2.jpg';
import stageClipLogo from 'assets/images/stage-clip-logo.png';
import { useLocale } from 'hooks/useLocale/useLocale';
import { Button, Card, Typography } from 'ui/atoms';

import { useStyles } from './SingleEvent.style';
import { SingleEventProps } from './SingleEvent.types';

export const SingleEvent = ({ institutionData }: SingleEventProps) => {
  const styles = useStyles();
  const { formatMessage } = useLocale();

  const openInNewTab = (url: string) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const { mutate: saveInstituitionDetails } = useCreateSendEmailRemainder({});

  const sendEmail = async (id: string, email?: string) => {
    await saveInstituitionDetails({
      body: {
        participantId: id,
        email: email,
      },
    });
  };

  return (
    <div className={styles.root}>
      <div className={styles.cardContainer}>
        <div className={styles.stageClipLogo}>
          <img src={stageClipLogo} alt="Logo" />
        </div>
        <div className={styles.content}>Find My Clip</div>
        <Card className={styles.card}>
          <div className={styles.icon}>
            <Checked />
          </div>
          <Typography className={styles.emailTitle}>{formatMessage({ id: 'clip.sent.successful.title' })}</Typography>

          {institutionData.map((item) => (
            <>
              <div>
                <div>
                  <Typography className={styles.fullName}>{item?.fullName}</Typography>
                  <Typography className={styles.emailDescription}>{item.eventData.institutionName}</Typography>
                  <Typography className={styles.startDate}>
                    {`${item.eventData.startDateOnly} at ${item.eventData.startTimeOnly}`}
                  </Typography>
                </div>
              </div>
              <div>
                <Button
                  onClick={() => {
                    openInNewTab(`${item.clipSiteUrl}`);
                    sendEmail(item.id);
                  }}
                  className={styles.button}
                >
                  {formatMessage({ id: 'global.actions.confirm' })}
                </Button>
              </div>
            </>
          ))}
        </Card>
      </div>

      <div className={styles.institutionContainer}>
        <Typography className={styles.clientText}>{formatMessage({ id: 'find.clips.institutions.title' })}</Typography>
        <div className={styles.imageContainer}>
          <img src={groupImg} alt="HeaderImage" className={styles.images} />
        </div>
      </div>
    </div>
  );
};
