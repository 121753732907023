import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { UseStylesProps } from './Dashboard.types';
import { TOPBAR_HEIGHT } from './Dashboard.utils';

export const useStyles = makeStyles<Theme, UseStylesProps>(({ breakpoints, palette: { primary, white } }) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    backgroundColor: primary.contrastText,

    // [breakpoints.up('lg')]: {
    //   paddingLeft: ({ isSidebarExpanded }) => (isSidebarExpanded ? SIDEBAR_WIDTH_EXPANDED : SIDEBAR_WIDTH),
    // },

    [breakpoints.between('md', 'lg')]: {
      paddingTop: TOPBAR_HEIGHT,
      // paddingLeft: ({ isSidebarDisplayed }) => (isSidebarDisplayed ? SIDEBAR_WIDTH_EXPANDED : 0),
    },

    // [breakpoints.down('sm')]: {
    //   paddingTop: spacing(7.75),
    //   // display: 'none',
    // },
  },
  content: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    backgroundColor: white.main,
  },
  sidebarContainer: {
    // paddingTop: TOPBAR_HEIGHT,
    [breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));
