import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import axios from 'axios';

import { onLogOutAction } from 'store/authenticationReducer/authenticationReducer';

import { Tokens } from './index.types';

export const api = axios.create({
  headers: {
    Accept: 'application/json, text/plain, */*',
    'sec-fetch-site': 'same-origin',
  },
});

export const apiWithToken = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'x-find-my-clip-token': process.env.REACT_APP_PUBLIC_PORTALS_ACCESS_TOKEN || '',
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
  validateStatus: () => true,
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    return Promise.reject(error.response);
  },
);

export const createClient = (previousTokens: Tokens, dispatch: Dispatch<AnyAction>) => {
  const client = axios.create({
    headers: {
      ...(previousTokens.token && {
        'Content-Type': 'application/json;charset=UTF-8',
        Accept: 'application/json, text/plain, */*',
        'x-xsrf-token': previousTokens.xsrfToken,
      }),
    },
  });

  client.interceptors.request.use((config) => {
    return {
      ...config,
      headers: {
        ...config.headers,
        ...(previousTokens.token && {
          Accept: 'application/json, text/plain, */*',
          'x-xsrf-token': previousTokens.xsrfToken,
        }),
      },
    };
  }, Promise.reject);

  client.interceptors.response.use(
    (response) => response,
    async ({ config, response }) => {
      if (response.status === 401 && !config._retry) {
        dispatch(onLogOutAction());
      }

      return Promise.reject(response);
    },
  );

  return client;
};
