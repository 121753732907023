import { useState } from 'react';
import { IntlProvider } from 'react-intl';

import { translations } from 'i18n/messages';

import { AppLocale } from '../AppLocale.enum';
import { defaultLocale } from '../defaultLocale';
import { LocaleContext } from '../localeContext/LocaleContext';

import { LocaleContextControllerProps } from './LocaleContextController.types';

export const LocaleContextController = ({ children }: LocaleContextControllerProps) => {
  const storedAppLocale: AppLocale | null = window.localStorage.getItem('locale') as AppLocale | null;
  const [locale, setLocale] = useState<AppLocale>(storedAppLocale || defaultLocale);

  const handleSetLocale = (locale: AppLocale) => {
    setLocale(locale);
    window.localStorage.setItem('locale', locale);
  };

  return (
    <IntlProvider fallbackOnEmptyString defaultLocale={defaultLocale} locale={locale} messages={translations[locale]}>
      <LocaleContext.Provider value={{ defaultLocale, locale, setLocale: handleSetLocale }}>
        {children}
      </LocaleContext.Provider>
    </IntlProvider>
  );
};
