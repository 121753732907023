import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { UseStylesProps } from './DateTimePicker.types';

export const useStyles = makeStyles<Theme, UseStylesProps>(({ spacing, palette }) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(1),
    fontSize: spacing(1.625),
  },
  helperText: {
    display: 'flex',
    fontSize: spacing(1.625),
    color: ({ error }) => (error ? palette.error.main : palette.gray.main),
    lineHeight: spacing(3),
    margin: spacing(1, 0, 0, 0),
  },
  helperIcon: {
    display: 'flex',
    alignItems: 'center',
    margin: spacing(0, 1, 0, 0),
  },
  inputField: { padding: spacing(1), borderRadius: `${spacing(0.5)}`, border: `1px solid ${palette.gray.light}` },
}));
