import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { TOPBAR_HEIGHT } from 'app/layout/dashboard/Dashboard.utils';
import checked from 'assets/icons/checked.svg';
import prevButton from 'assets/icons/circle-left-line.svg';
import nextButton from 'assets/icons/circle-right-line.svg';
import { darkBlue } from 'theme/palette';

export const useStyles = makeStyles<Theme>(
  ({
    shadows,
    spacing,
    palette: { white, green, black, grey, text, primary, lightBlue, error, gray },
    typography: { fontWeightBold, fontWeightRegular, fontWeightMedium },
    breakpoints,
  }) => ({
    content: {
      display: 'flex',
      justifyContent: 'center',
      fontFamily: 'Open Sans',
      fontSize: spacing(6),
      fontWeight: fontWeightBold,
      marginBottom: spacing(0),
    },
    container: {
      backgroundColor: white.dark,
    },
    emailTitle: {
      fontWeight: `${fontWeightBold} !important`,
      fontSize: `${spacing(3.5)} !important`,
      justifyContent: 'center',
      display: 'flex',
      fontFamily: `Open Sans !important`,
      marginBottom: `${spacing(0.25)} !important`,
      lineHeight: spacing(5),
    },
    emailDescription: {
      fontSize: spacing(2),
      justifyContent: 'center',
      display: 'flex',
      fontFamily: `Open Sans !important`,
      textAlign: 'center',
      marginBottom: `${spacing(1)} !important`,
    },
    card: {
      margin: 'auto',
      width: spacing(50),
      // height: spacing(36),
      backgroundColor: white.light,
      borderRadius: spacing(1.25),
      padding: spacing(2),
    },
    emailInput: {
      padding: spacing(1),
      margin: `${spacing(1, 0, 2, 0)} !important`,
      fontFamily: 'Roboto !important',
    },
    institutionsDropdown: {
      padding: spacing(1),
      marginLeft: '-0.5rem',
    },
    buttonOne: {
      width: '100%',
      backgroundColor: `${darkBlue} !important`,
      color: `${white.main} !important`,
      marginTop: `${spacing(2)} !important`,
      fontSize: `${spacing(2)} !important`,
    },
    errorText: {
      fontWeight: `${fontWeightBold} !important`,
      fontSize: `${spacing(1.6)} !important`,
      justifyContent: 'center',
      display: 'flex',
      fontFamily: `Open Sans !important`,
      marginBottom: `${spacing(0.25)} !important`,
      lineHeight: spacing(5),
      color: 'red',
    },

    buttonTwo: {
      width: '100%',
      backgroundColor: `${green.main} !important`,
      color: `${white.main} !important`,
      marginTop: `${spacing(2)} !important`,
      fontSize: `${spacing(2)} !important`,
      '&:hover': {
        backgroundColor: `${green.main} !important`,
        color: `${white.main} !important`,
      },
      '&:disabled': {
        color: `${grey} !important`,
        backgroundColor: `${grey[500]} !important`,
      },
    },
    clientText: {
      fontSize: `${spacing(3.5)} !important`,
      fontStyle: `Open Sans !important`,
      textAlign: 'center',
      fontWeight: `${fontWeightBold}  !important`,
      color: '#595959',
      marginTop: `${spacing(5)} !important`,
    },
    calendarIcon: {
      width: spacing(2.5),
      height: spacing(2.5),
    },
    imageContainer: {
      display: 'flex',
      padding: spacing(4),
      justifyContent: 'center',
      HeaderImage: {
        padding: spacing(4),
      },
      margin: spacing(1, 4, 0, 4),
    },
    images: {
      margin: spacing(0, 6, 0, 6),
    },
    root: {
      height: `calc(100vh - ${TOPBAR_HEIGHT}px)`,
      // height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    cardContainer: {
      [breakpoints.up('md')]: {
        marginTop: spacing(0),
      },
    },
    link: {
      display: 'flex',
      justifyContent: 'center',
      color: `${black.dark} !important`,
      fontWeight: `${fontWeightBold} !important`,
      marginTop: `${spacing(1)} !important`,
      fontFamily: `Open Sans !important`,
    },
    icon: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: spacing(1),
    },
    institutionContainer: {
      [breakpoints.down('md')]: {
        display: 'none',
      },
      marginBottom: `${spacing(6)} !important`,
    },
    stageClipLogo: {
      height: '10%',
      [breakpoints.up('md')]: {
        display: 'none',
      },
      margin: spacing(1, 0, 1, 2.5),
      display: 'flex',
      justifyContent: 'center',
      marginBottom: `${spacing(1)} !important`,
    },
    dateContainer: {
      marginTop: `${spacing(1)} !important`,
      marginBottom: `${spacing(1)} !important`,
    },
    datePicker: {
      marginTop: `${spacing(1)} !important`,
      marginBottom: `${spacing(1)} !important`,
      position: 'relative',
      width: '100%',
      '& .react-datepicker-popper': {
        zIndex: 3,
      },
      '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
        height: `${spacing(4.375)} !important`,
        fontSize: `${spacing(1.75)} !important`,
        width: '90%',
        background: white.main,
        borderTopLeftRadius: spacing(0.75),
        borderBottomLeftRadius: spacing(0.75),
      },
      '& .Mui-disabled .MuiInputAdornment-root': {
        background: gray.dark,
      },
      '& .react-datepicker__navigation-icon': {
        display: 'none',
      },
      '& input': {
        width: `calc(100% - ${spacing(6)})`,
        boxSizing: 'border-box',
        height: '100%',
        paddingRight: 0,
      },
      '& .MuiInputAdornment-positionEnd': {
        pointerEvents: 'none',
        height: `${spacing(4.25)} !important`,
        width: `${spacing(4.25)} !important`,
        maxHeight: spacing(6),
        margin: 0,
        justifyContent: 'center',
        background: green.main,
        zIndex: 1,
        cursor: 'pointer',
        borderRadius: spacing(0, 0.75, 0.75, 0),
        '& svg path': {
          fill: white.main,
        },
      },
      '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
        borderColor: error.main,
      },
      '& .Mui-error .MuiInputAdornment-positionEnd, & .Mui-error.Mui-disabled .MuiInputAdornment-positionEnd': {
        background: error.main,
      },
      '& .MuiOutlinedInput-root:not(.Mui-error):not(.Mui-disabled).Mui-focused fieldset': {
        borderColor: primary.main,
      },
      '& .MuiOutlinedInput-root': {
        margin: 0,
        padding: 0,
      },
      '& .react-datepicker-wrapper': {
        width: '100%',
      },
      '& .react-datepicker': {
        boxShadow: shadows[2],
        border: 0,
        borderRadius: spacing(1.25),
        overflow: 'hidden',
      },
      '& .react-datepicker__header': {
        background: white.main,
        border: 0,
      },
      '& .react-datepicker__current-month': {
        paddingTop: spacing(1),
        fontSize: spacing(2.25),
        lineHeight: spacing(3.5),
      },
      '& .react-datepicker__day-name': {
        color: gray.dark,
        margin: 0,
        userSelect: 'none',
        fontWeight: fontWeightBold,
        fontSize: spacing(1.5),
        lineHeight: 1.5,
        width: spacing(5),
        textTransform: 'uppercase',
      },
      '& .react-datepicker__day': {
        width: spacing(5),
        height: spacing(5),
        margin: 0,
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        userSelect: 'none',
        color: text.primary,
        fontSize: spacing(1.8),
        lineHeight: spacing(3),
        fontWeight: fontWeightMedium,
        '&:focus': {
          outline: 'none',
        },
      },
      '& .react-datepicker__week': {
        display: 'flex',
      },
      '& .react-datepicker__month': {
        margin: spacing(0, 2, 2, 2),
      },
      '& .react-datepicker__day-names': {
        margin: spacing(2, 2, 1.5),
      },
      '& .react-datepicker__day--today': {
        color: primary.main,
        fontWeight: fontWeightBold,
      },
      '& .react-datepicker__day--keyboard-selected': {
        background: 'transparent',
      },
      '& .react-datepicker__month-text--keyboard-selected, & .react-datepicker__quarter-text--keyboard-selected, & .react-datepicker__year-text--keyboard-selected, & .react-datepicker__day--selected, & .react-datepicker__day--in-selecting-range, & .react-datepicker__day--in-range, & .react-datepicker__month-text--selected, & .react-datepicker__month-text--in-selecting-range, & .react-datepicker__month-text--in-range, & .react-datepicker__quarter-text--selected, & .react-datepicker__quarter-text--in-selecting-range, & .react-datepicker__quarter-text--in-range, & .react-datepicker__year-text--selected, & .react-datepicker__year-text--in-selecting-range, & .react-datepicker__year-text--in-range':
        {
          background: primary.main,
          color: white.main,
          fontWeight: fontWeightBold,
        },
      '& .react-datepicker__day--outside-month': {
        color: gray.dark,
        fontWeight: fontWeightRegular,
      },
      '& .react-datepicker__navigation': {
        width: spacing(3),
        height: spacing(3),
        border: 'none',
        top: spacing(2.5),
        '&:focus': {
          outline: 'none',
        },
      },
      '& .react-datepicker__navigation--next': {
        background: `url(${nextButton}) no-repeat`,
        right: spacing(3.25),
      },
      '& .react-datepicker__navigation--previous': {
        background: `url(${prevButton}) no-repeat`,
        left: spacing(3.25),
      },
      '& .react-datepicker__time-container': {
        width: spacing(26),
      },
      '& .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box': {
        width: '100%',
      },
      '& .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected':
        {
          background: 'transparent',
          color: text.primary,
          fontWeight: fontWeightRegular,
          position: 'relative',
          '&::before': {
            content: '""',
            background: `url(${checked}) no-repeat`,
            backgroundSize: '100%',
            width: spacing(1.375),
            height: spacing(1),
            position: 'absolute',
            left: spacing(1.625),
          },
        },
      '& .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item':
        {
          display: 'flex',
          alignItems: 'center',
          paddingLeft: spacing(4),
          userSelect: 'none',
          fontSize: spacing(1.8),
          lineHeight: spacing(3),
          '&:hover': {
            background: lightBlue.light,
          },
        },
      '& .react-datepicker__header--time.react-datepicker__header--time--only': {
        display: 'none',
      },
      '& .react-datepicker__day--disabled, & .react-datepicker__month-text--disabled, & .react-datepicker__quarter-text--disabled, & .react-datepicker__year-text--disabled':
        {
          color: white.main,
        },
    },
  }),
);
