import { AxiosInstance } from 'axios';
import { UseMutationOptions, useMutation, useQuery } from 'react-query';

import { apiWithToken } from 'api';
import { Errors } from 'api/index.types';

import {
  EmailReminder,
  EventTimeResponse,
  GetClip,
  GetCustomSearch,
  GetEventTimes,
  InstitutionListResponse,
  InstitutionsResponse,
} from './institutions.types';

const getInstitutions = ({ params }: GetClip) =>
  apiWithToken.get<InstitutionsResponse>('/find-my-clip/search', { params }).then(({ data }) => data);

export const useGetInstitutions = (
  options?: UseMutationOptions<InstitutionsResponse, Errors<{ error: string[] }>, GetClip, unknown> | undefined,
) => useMutation(getInstitutions, options);

// eslint-disable-next-line no-empty-pattern
const getAllInstitutionsList = ({}: { client: AxiosInstance }) =>
  apiWithToken.get<InstitutionListResponse>('/find-my-clip/institutions').then(({ data }) => data);

export const useGetAllInstitutionsList = ({ client }: { client: AxiosInstance }) =>
  useQuery('getAllInstitutionsList', () => getAllInstitutionsList({ client }), {});

const getEventTimes = ({ params }: GetEventTimes) =>
  apiWithToken.get<EventTimeResponse>('/find-my-clip/get-event-times', { params }).then(({ data }) => data);

export const useGetEventTimes = (
  options?: UseMutationOptions<EventTimeResponse, Errors<{ error: string[] }>, GetEventTimes, unknown> | undefined,
) => useMutation(getEventTimes, options);

const createSendEmailRemainder = ({ body }: EmailReminder) =>
  apiWithToken.post(`/find-my-clip/send-reminder-email`, body).then();

export const useCreateSendEmailRemainder = (
  options?: UseMutationOptions<unknown, Errors<unknown>, unknown> | undefined,
) => useMutation(createSendEmailRemainder, options);

const getCustomSearch = ({ params }: GetCustomSearch) =>
  apiWithToken.get<InstitutionsResponse>('/find-my-clip/custom-search', { params }).then(({ data }) => data);

export const useGetCustomSearch = (
  options?: UseMutationOptions<InstitutionsResponse, Errors<{ error: string[] }>, GetCustomSearch, unknown> | undefined,
) => useMutation(getCustomSearch, options);
