import { AppRoute } from './AppRoute.enum';

export const getPageTitle = (path: string | AppRoute) => {
  switch (path) {
    case AppRoute.clips:
      return 'app_routes.page_title.conversation';
    default:
      return 'app_routes.page_title.defualt';
  }
};
