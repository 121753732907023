// import ChatBubbleIcon from '@mui/icons-material/ChatBubble';

import MessageMatrixLogo from 'assets/icons/clips.svg';
import { AppRoute } from 'routing/AppRoute.enum';
import { Tooltip } from 'ui/atoms';

export const menuItems = [
  {
    icon: (
      <Tooltip title="Clips">
        <img src={MessageMatrixLogo} alt="Logo" />
      </Tooltip>
    ),
    path: AppRoute.clips,
    id: 'dashboard.sidebar.icons.conversation',
  },
];

export const TOPBAR_HEIGHT = 56;
export const SIDEBAR_WIDTH = 0;
export const SIDEBAR_WIDTH_EXPANDED = 180;
