import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import { LocaleContextController } from 'context/locale/localeContextController/LocaleContextController';
import { ReactQueryController } from 'context/reactQueryController/ReactQueryController';
import { Theme } from 'theme/Theme';

import { store, persistor } from '../store';

import { AppProvidersProps } from './AppProviders.types';

export const AppProviders = ({ children }: AppProvidersProps) => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Theme>
          <LocaleContextController>
            <Router>
              <ReactQueryController>{children}</ReactQueryController>
            </Router>
          </LocaleContextController>
        </Theme>
      </PersistGate>
    </Provider>
  );
};
