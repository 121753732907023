import { useForm } from 'react-hook-form';

import { useGetAllInstitutionsList } from 'api/institutions/institutions';
import { useClient } from 'hooks/useClient/useClient';

import { ClipFindOtherMethod } from './ClipOtherMethod';
import { ClipSettingsInputs } from './ClipOtherMethod.types';

export const ClipOtherMethodContainer = () => {
  const { control } = useForm<ClipSettingsInputs>({});

  const client = useClient();

  const { data, isLoading } = useGetAllInstitutionsList({ client });

  return <ClipFindOtherMethod data={data} isLoading={isLoading} control={control} />;
};
