import groupImg from 'assets/images/group-2.jpg';
import stageClipLogo from 'assets/images/stage-clip-logo.png';
import { useLocale } from 'hooks/useLocale/useLocale';
import { Card, Typography } from 'ui/atoms';

import { useStyles } from './NotFound.styles';

export const NotFoundPage = () => {
  const styles = useStyles();
  const { formatMessage } = useLocale();

  return (
    <div className={styles.root}>
      <div className={styles.cardContainer}>
        <div className={styles.stageClipLogo}>
          <img src={stageClipLogo} alt="Logo" />
        </div>

        <Card className={styles.card}>
          <Typography className={styles.emailTitle}>Oops! Page Not Found</Typography>
        </Card>
      </div>

      <div className={styles.institutionContainer}>
        <Typography className={styles.clientText}>{formatMessage({ id: 'find.clips.institutions.title' })}</Typography>
        <div className={styles.imageContainer}>
          <img src={groupImg} alt="HeaderImage" className={styles.images} />
        </div>
      </div>
    </div>
  );
};
