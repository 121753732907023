import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>(({ spacing }) => {
  return {
    root: {
      width: `${spacing(6)} !important`,
      height: `${spacing(6)} !important`,
      borderRadius: '50%',
    },
  };
});
