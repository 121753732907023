import React from 'react';
import { useLocation } from 'react-router-dom';

import { ReactComponent as Alert } from 'assets/icons/alert-icon.svg';
import groupImg from 'assets/images/group-2.jpg';
import stageClipLogo from 'assets/images/stage-clip-logo.png';
import { useLocale } from 'hooks/useLocale/useLocale';
import { Card, Typography } from 'ui/atoms';

import { useStyles } from './ClipInProgress.style';

export const ClipInProgress = () => {
  const styles = useStyles();
  const { state } = useLocation();
  const { formatMessage } = useLocale();

  const obscureEmail = (email: string) => {
    const parts = email.split('@');
    const username = parts[0];
    const domain = parts[1];
    const obscuredUsername = username.charAt(0) + username.charAt(1) + '▪▪▪▪';
    const obscuredDomain = domain.charAt(0) + '▪▪▪▪' + domain.slice(-3);

    return `${obscuredUsername}@${obscuredDomain}`;
  };

  return (
    <div className={styles.root}>
      <div className={styles.cardContainer}>
        <div className={styles.stageClipLogo}>
          <img src={stageClipLogo} alt="Logo" />
        </div>
        <div className={styles.content}>Find My Clip</div>
        <Card className={styles.card}>
          <div className={styles.icon}>
            {/* <EmailIcon /> */}
            <Alert />
          </div>
          <Typography className={styles.systemStatus}>System Status</Typography>
          <Typography className={styles.emailTitle}>{formatMessage({ id: 'clip.InProgress.title' })}</Typography>
          <Typography className={styles.emailDescription}>
            {` Hello ${state.data?.firstName}! `} <span className={styles.overrideColor}> 🌼 </span> <br />
            {`You're officially in the StageClilp system and we’re working our clip magic!`}
            <span className={styles.overrideColor}> 🎬✨ </span>
            {`Stay tuned for your special moment which will be sent by email to ${obscureEmail(state.email)}`}
            {state.data.eventData.publicationType === 'AsSoonAsClipAreReady' && (
              <>(publication status – ‘when ready’!)</>
            )}
            {state.data.eventData.publicationDateAndTime !== null && (
              <> (on {state.data.eventData.publicationDateAndTime} as requested by your institution)!</>
            )}
            <span className={styles.overrideColor}> 🌟🎥 </span>
            {state.data.portalUrl && state.data.clipSearchEnable && (
              <>
                You can also pop back{' '}
                <a
                  className={styles.clipLink}
                  href={state.data?.portalUrl ? state.data?.portalUrl : ''}
                  target="_blank"
                  rel="noreferrer"
                >
                  here
                </a>{' '}
                to catch the excitement! <span className={styles.overrideColor}> 💌 </span>
              </>
            )}
          </Typography>

          <div>
            <Typography className={styles.differentEmail}>
              {formatMessage({ id: 'clip.not.available.different.email' })}
              <br />
              {formatMessage({ id: 'clip.raise.ticket' })}
            </Typography>
          </div>
          <div>
            <a
              className={styles.linkTicket}
              href="https://stageclip.com/student-support/"
              target="_blank"
              rel="noreferrer"
            >
              here
            </a>
          </div>
        </Card>
      </div>

      <div className={styles.institutionContainer}>
        <Typography className={styles.clientText}>{formatMessage({ id: 'find.clips.institutions.title' })}</Typography>
        <div className={styles.imageContainer}>
          <img src={groupImg} alt="HeaderImage" className={styles.images} />
        </div>
      </div>
    </div>
  );
};
