import { Navigate, Route, Routes } from 'react-router-dom';

import { ClipCloseSuccess } from 'app/clips/clipCloseSuccess/ClipCloseSuccess';
import { ClipDeleted } from 'app/clips/clipDeleted/ClipDeleted';
import { ClipInProgress } from 'app/clips/clipInProgress/ClipInProgress';
import { ClipsContainer } from 'app/clips/ClipsContainer';
import { ClipSent } from 'app/clips/clipSent/ClipSent';
import { ClipUserExist } from 'app/clips/clipUserExist/ClipUserExist';
import { ClipWaitingAssets } from 'app/clips/clipWaitingAssets/ClipWaitingAssets';
import { NoClipAvailable } from 'app/clips/noClip/NoClipAvailable';
import { NoClipButUserExists } from 'app/clips/noClip-ButUserExists/noClipButUserExists';
import { NoNameList } from 'app/clips/noName/NoNameList';
import { RaiseTicket } from 'app/clips/raiseTicket/RaiseTicket';
import { AddTicket } from 'app/clips/ticket/AddTicket';
import { ClipOtherMethodContainer } from 'app/email/anotherMethod/clipotherMethod/ClipOtherMethodContainer';
import { FindClipWithAnotherMethod } from 'app/email/anotherMethod/FindClipWithAnotherMethod';
import { CheckEmail } from 'app/email/CheckEmail';
import { MultiEventContainerContainer } from 'app/events/multiEvent/MultiEventContainer';
import { SingleEventContainer } from 'app/events/singleEvent/SingleEventContainer';
import { DashboardLayout } from 'app/layout/dashboard/Dashboard';
import { NotFoundPage } from 'app/notFound/NotFound';

import { AppRoute } from './AppRoute.enum';

export const ProtectedRoutes = () => {
  return (
    <Routes>
      <Route
        path={AppRoute.clips}
        element={
          <DashboardLayout>
            <ClipsContainer />
          </DashboardLayout>
        }
      />
      <Route
        path={AppRoute.checkEmail}
        element={
          <DashboardLayout>
            <CheckEmail />
          </DashboardLayout>
        }
      />
      <Route
        path={AppRoute.sentCustomEmail}
        element={
          <DashboardLayout>
            <FindClipWithAnotherMethod />
          </DashboardLayout>
        }
      />
      <Route
        path={AppRoute.clipFindOtherMethod}
        element={
          <DashboardLayout>
            <ClipOtherMethodContainer />
          </DashboardLayout>
        }
      />

      <Route
        path={AppRoute.createTicket}
        element={
          <DashboardLayout>
            <AddTicket />
          </DashboardLayout>
        }
      />
      <Route
        path={AppRoute.clipNotAvailable}
        element={
          <DashboardLayout>
            <NoClipAvailable />
          </DashboardLayout>
        }
      />
      <Route
        path={AppRoute.clipSentSuccessful}
        element={
          <DashboardLayout>
            <ClipSent />
          </DashboardLayout>
        }
      />

      <Route
        path={AppRoute.singleEventSuccess}
        element={
          <DashboardLayout>
            <SingleEventContainer />
          </DashboardLayout>
        }
      />

      <Route
        path={AppRoute.multiEventSuccess}
        element={
          <DashboardLayout>
            <MultiEventContainerContainer />
          </DashboardLayout>
        }
      />
      <Route
        path={AppRoute.clipInProgress}
        element={
          <DashboardLayout>
            <ClipInProgress />
          </DashboardLayout>
        }
      />
      <Route
        path={AppRoute.clipWaitingAssets}
        element={
          <DashboardLayout>
            <ClipWaitingAssets />
          </DashboardLayout>
        }
      />
      <Route
        path={AppRoute.noNameList}
        element={
          <DashboardLayout>
            <NoNameList />
          </DashboardLayout>
        }
      />
      <Route
        path={AppRoute.raiseTicket}
        element={
          <DashboardLayout>
            <RaiseTicket />
          </DashboardLayout>
        }
      />
      <Route
        path={AppRoute.notFound}
        element={
          <DashboardLayout>
            <NotFoundPage />
          </DashboardLayout>
        }
      />
      <Route
        path={AppRoute.clipDeleted}
        element={
          <DashboardLayout>
            <ClipDeleted />
          </DashboardLayout>
        }
      />
      <Route
        path={AppRoute.clipUserExist}
        element={
          <DashboardLayout>
            <ClipUserExist />
          </DashboardLayout>
        }
      />
      <Route
        path={AppRoute.noClipUserExist}
        element={
          <DashboardLayout>
            <NoClipButUserExists />
          </DashboardLayout>
        }
      />
      <Route
        path={AppRoute.clipCloseSuccess}
        element={
          <DashboardLayout>
            <ClipCloseSuccess />
          </DashboardLayout>
        }
      />

      <Route path="*" element={<Navigate to={AppRoute.notFound} replace />} />
    </Routes>
  );
};
