// import { Link } from '@mui/material';

import { useNavigate } from 'react-router-dom';

import { ReactComponent as EmailIcon } from 'assets/images/email-icon.svg';
// import img1 from 'assets/images/j-hu.png';
// import img3 from 'assets/images/ub.png';
// import img4 from 'assets/images/ub.png';
import groupImg from 'assets/images/group-2.jpg';
import stageClipLogo from 'assets/images/stage-clip-logo.png';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppRoute } from 'routing/AppRoute.enum';
import { Button, Card, Typography } from 'ui/atoms';

import { useStyles } from './CheckEmail.style';

export const CheckEmail = () => {
  const styles = useStyles();
  const { formatMessage } = useLocale();
  const navigate = useNavigate();

  return (
    <div className={styles.root}>
      <div className={styles.cardContainer}>
        <div className={styles.stageClipLogo}>
          <img src={stageClipLogo} alt="Logo" />
        </div>
        <div className={styles.content}>Find My Clip</div>
        <Card className={styles.card}>
          <div className={styles.icon}>
            <EmailIcon />
          </div>
          <Typography className={styles.emailTitle}>{formatMessage({ id: 'email.check.title' })}</Typography>

          <Typography className={styles.emailDescription}>
            {formatMessage({ id: 'email.check.description' })} <br />
            {formatMessage({ id: 'email.check.try.again' })}
          </Typography>
          <div>
            <Button className={styles.button} onClick={() => navigate(AppRoute.sentCustomEmail)}>
              {formatMessage({ id: 'email.check.button' })}
            </Button>
          </div>
        </Card>
      </div>

      <div className={styles.institutionContainer}>
        <Typography className={styles.clientText}>{formatMessage({ id: 'find.clips.institutions.title' })}</Typography>
        <div className={styles.imageContainer}>
          <img src={groupImg} alt="HeaderImage" className={styles.images} />
        </div>
      </div>
    </div>
  );
};
