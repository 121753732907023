import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import { ClipsContainer } from 'app/clips/ClipsContainer';
// import { LoginContainer } from 'app/login/LoginContainer';
import { useLocale } from 'hooks/useLocale/useLocale';
import { allReducerStates } from 'store/store.utils';

import { AppRoute } from './AppRoute.enum';
import { getPageTitle } from './AppRoutes.utils';
import { ProtectedRoutes } from './ProtectedRoutes';

export const AppRoutes = () => {
  const location = useLocation();
  const { formatMessage } = useLocale();
  const {
    auth: { isAuthorized },
  } = useSelector(allReducerStates);

  const navigate = useNavigate();

  useEffect(() => {
    const pageTitle = getPageTitle(location.pathname);
    document.title = formatMessage({ id: pageTitle });
    // eslint-disable-next-line react-hooks/exhaustive-deps

    const handleBeforeUnload = () => {
      <Route path={AppRoute.clips} element={<ClipsContainer />} />;
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [formatMessage, location.pathname, navigate]);

  if (!isAuthorized) {
    return <ProtectedRoutes />;
  }

  return (
    <Routes>
      <Route path={AppRoute.clips} element={<ClipsContainer />} />
      <Route path="*" element={<Navigate to={AppRoute.notFound} replace />} />
    </Routes>
  );
};
