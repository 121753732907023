import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';

import { MultiEvent } from './MultiEvent';
import { MultiEventInputs } from './MultiEvent.types';

export const MultiEventContainerContainer = () => {
  const { control } = useForm<MultiEventInputs>();
  const { state } = useLocation();

  return <MultiEvent control={control} institutionData={state.data} email={state.email} />;
};
