import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

// import { TOPBAR_HEIGHT } from '../Dashboard.utils';

import { UseStylesProps } from './TopBar.types';

export const useStyles = makeStyles<Theme, UseStylesProps>(
  ({ breakpoints, spacing, palette: { gray, white, black } }) => ({
    topbar: {
      width: '100%',
      position: 'relative',
      display: 'flex',
      boxShadow: ` ${spacing(0, 0.1, 1, 0)}  ${gray.main}`,
      // [breakpoints.down('sm')]: {
      //   borderBottom: ({ isSidebarDisplayed }) => (isSidebarDisplayed ? 'none' : spacing(0.25, 'solid', gray.main)),
      //   padding: spacing(1.5, 3),
      //   height: ({ isSidebarDisplayed }) => spacing(isSidebarDisplayed ? 7.75 : 8),
      // },
      height: spacing(8),
    },
    MessageMatrixLogo: {
      cursor: 'pointer',
      height: '65%',
      [breakpoints.down('sm')]: {
        display: 'none',
      },
      margin: spacing(1, 0, 1, 2.5),
    },
    MessageMatrixLogoMobile: {
      cursor: 'pointer',
      height: '100%',
      marginLeft: spacing(2.5),
      [breakpoints.up('sm')]: {
        display: 'none',
      },
    },
    hamburgerMenu: {
      color: black.main,
      border: 'none',
      cursor: 'pointer',
      backgroundColor: 'transparent',
      backgroundSize: '100%',
      display: 'flex',
      [breakpoints.up('sm')]: {
        marginRight: spacing(3),
      },
      [breakpoints.up('md')]: {
        display: 'none',
      },
    },
    profileItem: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: `${white.main} !important`,
      fontWeight: 'bold',
      textDecoration: 'none !important',
      cursor: 'pointer',
      gap: spacing(1),
      '&:not(:last-child)': {
        marginBottom: spacing(3),
      },
      '&:hover': {
        textDecoration: 'none',
      },
      marginRight: `${spacing(1)} !important`,
    },
    avatarProfile: {
      width: `${spacing(5)} !important`,
      height: `${spacing(5)} !important`,
    },
  }),
);
