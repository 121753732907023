import { ReactComponent as Checked } from 'assets/icons/checkmark-circle.svg';
import groupImg from 'assets/images/group-2.jpg';
import stageClipLogo from 'assets/images/stage-clip-logo.png';
import { useLocale } from 'hooks/useLocale/useLocale';
import { Card, Typography } from 'ui/atoms';

import { useStyles } from './ClipSent..style';

export const ClipSent = () => {
  const styles = useStyles();
  const { formatMessage } = useLocale();

  return (
    <div className={styles.root}>
      <div className={styles.cardContainer}>
        <div className={styles.stageClipLogo}>
          <img src={stageClipLogo} alt="Logo" />
        </div>
        <div className={styles.content}>Find My Clip</div>
        <Card className={styles.card}>
          <div className={styles.icon}>
            <Checked />
          </div>
          <Typography className={styles.emailTitle}>{formatMessage({ id: 'clip.sent.successful.title' })}</Typography>
          <Typography className={styles.emailDescription}>
            {formatMessage({ id: 'clip.sent.successful.description' })}
          </Typography>
        </Card>
      </div>

      <div className={styles.institutionContainer}>
        <Typography className={styles.clientText}>{formatMessage({ id: 'find.clips.institutions.title' })}</Typography>
        <div className={styles.imageContainer}>
          <img src={groupImg} alt="HeaderImage" className={styles.images} />
        </div>
      </div>
    </div>
  );
};
