export type GetClip = {
  params?: {
    email?: string;
  };
};

export type GetEventTimes = {
  params?: {
    institutionId?: string;
    eventDate?: string;
  };
};

export type GetCustomSearch = {
  params?: {
    eventId?: string;
    firstName?: string;
    lastName?: string;
  };
};

export type InstitutionDetails = {
  id: string;
  fullName: string;
  firstName: string;
  subtitle: string;
  clipSiteUrl: string;
  isClipSiteUrlInclude: string;
  clipSearchEnable: boolean;
  eventData: EventInfo;
};

export type InstitutionList = {
  rows: {
    id: string;
    name: string;
  }[];
};

export type EventTime = {
  eventId: string;
  eventTime: string;
  institutionName: string;
  type: string;
  state: string;
  producerStatus: string;
  timezone: string;
  startDateOnly: string;
  startTimeOnly: string;
  isIncludeParticipant: boolean;
  isThemeSelected?: boolean;
  isEventPublish?: boolean;
  isEventVideoInclude?: boolean;
  eventIsArchived?: boolean;
  publicationType?: string;
  publicationDateAndTime?: string;
  clipSearchEnable?: string;
  portalUrl?: string;
};

export type EmailReminderBody = {
  participantId: string;
  email?: string;
};

export type EmailReminder = {
  body: EmailReminderBody;
};

export type InstitutionsResponse = InstitutionDetails[];
export type InstitutionListResponse = InstitutionList;
export type EventTimeResponse = EventTime[];

export type EventInfo = {
  id: string;
  name: string;
  institutionName: string;
  type: string;
  state: EventState;
  producerStatus: EventProducerStatus;
  timezone: string;
  startDateOnly: string;
  startTimeOnly: string;
  isIncludeParticipant: boolean;
  isThemeSelected: boolean;
  isReviewCeremony: boolean;
  isReadyToPublish: boolean;
  isEventPublish: boolean;
  isEventVideoInclude: boolean;
  isClipsOnly: boolean;
  eventIsArchived: boolean;
};

export enum EventState {
  WaitingForAssets = 'Waiting for Assets',
  ReadyToClip = 'Ready to Clip',
  ClippingInProgress = 'Clipping in Progress',
  ReviewNeeded = 'Review Needed',
  Complete = 'Complete',
  Cancelled = 'Cancelled',
}

export enum EventProducerStatus {
  NotStarted = 'NotStarted',
  InProgress = 'InProgress',
  ForReview = 'ForReview',
  ReadyToPublish = 'ReadyToPublish',
  Published = 'Published',
}
