import { ReactComponent as Alert } from 'assets/icons/alert-icon.svg';
import groupImg from 'assets/images/group-2.jpg';
import stageClipLogo from 'assets/images/stage-clip-logo.png';
import { useLocale } from 'hooks/useLocale/useLocale';
import { Card, Typography } from 'ui/atoms';

import { useStyles } from './noClipButUserExists.styles';

export const NoClipButUserExists = () => {
  const styles = useStyles();
  const { formatMessage } = useLocale();

  return (
    <div className={styles.root}>
      <div className={styles.cardContainer}>
        <div className={styles.stageClipLogo}>
          <img src={stageClipLogo} alt="Logo" />
        </div>
        <div className={styles.content}>Find My Clip</div>
        <Card className={styles.card}>
          <div className={styles.icon}>
            {/* <EmailIcon /> */}
            <Alert />
          </div>
          <Typography className={styles.systemStatus}>System Status</Typography>

          <Typography className={styles.emailTitle}>{formatMessage({ id: 'clip.user.exist.noclip.title' })}</Typography>

          <Typography className={styles.emailDescription}>
            {formatMessage({ id: 'clip.user.exist.noclip.description' })}
          </Typography>
          <div>
            <Typography className={styles.differentEmail}>
              {formatMessage({ id: 'clip.not.available.different.email' })}
              <br />
              {formatMessage({ id: 'clip.raise.ticket' })}
            </Typography>
          </div>
          <div>
            <a
              className={styles.linkTicket}
              href="https://stageclip.com/student-support/"
              target="_blank"
              rel="noreferrer"
            >
              here
            </a>
          </div>
        </Card>
      </div>

      <div className={styles.institutionContainer}>
        <Typography className={styles.clientText}>{formatMessage({ id: 'find.clips.institutions.title' })}</Typography>
        <div className={styles.imageContainer}>
          <img src={groupImg} alt="HeaderImage" className={styles.images} />
        </div>
      </div>
    </div>
  );
};
