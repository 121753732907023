import { joiResolver } from '@hookform/resolvers/joi';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { useCreateSendEmailRemainder, useGetInstitutions } from 'api/institutions/institutions';
import { EventState, InstitutionsResponse } from 'api/institutions/institutions.types';
import { AppRoute } from 'routing/AppRoute.enum';

import { Clips } from './Clips';
import { ClipsInputs } from './Clips.types';
import { defaultValues, schema } from './Clips.utils';
// import { BaseSyntheticEvent } from 'react';

export const ClipsContainer = () => {
  const navigate = useNavigate();

  const {
    watch,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ClipsInputs>({
    reValidateMode: 'onChange',
    resolver: joiResolver(schema),
    mode: 'onChange',
    defaultValues: defaultValues,
  });
  const { email } = watch();

  const { mutate: saveInstituitionDetails } = useCreateSendEmailRemainder({});

  const sendEmail = async (id: string, email?: string) => {
    await saveInstituitionDetails({
      body: {
        participantId: id,
        email: email,
      },
    });
  };

  const { mutate, isLoading } = useGetInstitutions({
    onSuccess: (data: InstitutionsResponse) => {
      if (!data?.length) {
        // No email
        navigate(AppRoute.checkEmail);
      } else if (data?.length > 1) {
        // multiple
        navigate(AppRoute.multiEventSuccess, { state: { data: data, email: email } });
      } else if (data[0].eventData.eventIsArchived) {
        // multiple
        navigate(AppRoute.clipDeleted);
      } else if (data[0]?.eventData.state === EventState.ClippingInProgress) {
        navigate(AppRoute.clipInProgress, { state: { data: data[0], email: email } });
      } else if (data[0]?.eventData.state === EventState.WaitingForAssets) {
        navigate(AppRoute.clipWaitingAssets, { state: { data: data[0], email: email } });
      } else if (data[0]?.eventData.state === EventState.ReviewNeeded) {
        navigate(AppRoute.noClipUserExist);
      } else if (data[0]?.eventData.state === EventState.ReadyToClip) {
        navigate(AppRoute.noClipUserExist);
      } else if (!data[0]?.clipSiteUrl && data[0]?.clipSearchEnable && !data[0].eventData.eventIsArchived) {
        navigate(AppRoute.noClipUserExist);
      } else if (data[0]?.clipSearchEnable && !data[0].eventData.eventIsArchived) {
        navigate(AppRoute.singleEventSuccess, { state: data });
      } else if (!data[0]?.clipSearchEnable && data[0].isClipSiteUrlInclude) {
        navigate(AppRoute.clipCloseSuccess, { state: { data: data, email: email } });
        sendEmail(data[0].id);
      }
    },
  });

  const handleOnSubmit = async () => {
    await mutate({ params: { email } });
  };

  return (
    <Clips
      register={register}
      errors={errors}
      email={email}
      isLoading={isLoading}
      onSubmit={handleSubmit(handleOnSubmit)}
    />
  );
};
