import { InputAdornment } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import moment from 'moment';
import { useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { useNavigate } from 'react-router-dom';

import { useGetCustomSearch, useGetEventTimes } from 'api/institutions/institutions';
import { EventState, EventTimeResponse, InstitutionsResponse } from 'api/institutions/institutions.types';
import { ReactComponent as CalendarIcon } from 'assets/icons/icon-calendar-outline.svg';
import groupImg from 'assets/images/group-2.jpg';
import stageClipLogo from 'assets/images/stage-clip-logo.png';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppRoute } from 'routing/AppRoute.enum';
import { DATE_FORMAT } from 'shared/date';
import { Button, Card, Input, Loader, Typography } from 'ui/atoms';
import { ForwardRefInput } from 'ui/atoms/forwardRefInput/ForwardRefInput';

import { useStyles } from './ClipotherMethod.style';
import { FindClipWithAnotherMethodProps, Option, OptionTime } from './ClipOtherMethod.types';

export const ClipFindOtherMethod = ({ data }: FindClipWithAnotherMethodProps) => {
  const styles = useStyles();
  const { formatMessage } = useLocale();
  const navigate = useNavigate();
  const [selectedValue, setSelectedValue] = useState<Option | null>(null);
  const [selectedTime, setSelectedTime] = useState<OptionTime | null>(null);
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');

  const [dateValue, setDateValue] = useState<Date | null>(null);

  const [timeData, setTimeData] = useState<EventTimeResponse>([]);

  const { mutate, isLoading } = useGetEventTimes({
    onSuccess: (data: EventTimeResponse) => {
      setTimeData(data);
    },
  });

  const { mutate: searchMutate } = useGetCustomSearch({
    onSuccess: (data: InstitutionsResponse) => {
      if (!data?.length || data?.length > 1) {
        navigate(AppRoute.raiseTicket);
      } else if (data[0]?.eventData.state === EventState.ClippingInProgress) {
        navigate(AppRoute.clipInProgress);
      } else if (data[0]?.eventData.state === EventState.WaitingForAssets) {
        navigate(AppRoute.clipWaitingAssets);
      } else if (data[0]?.clipSearchEnable && !data[0].eventData.eventIsArchived) {
        navigate(AppRoute.singleEventSuccess, { state: data });
      }
    },
  });

  if (!data?.rows) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleSelect = (event: React.ChangeEvent<{}>, value: Option | null) => {
    setSelectedValue(value);
    setTimeData([]);
    setDateValue(null);
  };

  const handleSelectTime = (event: React.ChangeEvent<object>, value: OptionTime | null) => {
    setSelectedTime(value);
  };

  const handleDateChange = async (date: Date | null) => {
    await mutate({
      params: { institutionId: selectedValue?.id, eventDate: moment(date).format('YYYY-MM-DD') },
    });
    setDateValue(date);
  };

  const handleSubmit = async () => {
    if (selectedTime?.isIncludeParticipant) {
      await searchMutate({
        params: { eventId: selectedTime?.eventId, firstName, lastName },
      });
    } else {
      navigate(AppRoute.noNameList, { state: { data: { ...selectedTime, fullName: firstName } } });
    }
  };

  return (
    <div className={styles.root}>
      <div className={styles.cardContainer}>
        <div className={styles.stageClipLogo}>
          <img src={stageClipLogo} alt="Logo" />
        </div>
        <div className={styles.content}>Find My Clip</div>
        <Card className={styles.card}>
          <Typography className={styles.emailTitle}>
            {formatMessage({ id: 'email.other.method.clip.title' })}
          </Typography>

          <Autocomplete
            size="small"
            id="combo-box-demo"
            className={styles.institutionsDropdown}
            options={data?.rows}
            getOptionLabel={(option) => option.name}
            onChange={handleSelect}
            defaultValue={data?.rows[1]}
            renderOption={(props, option) => {
              return (
                <li {...props} key={option.id}>
                  {option.name}
                </li>
              );
            }}
            sx={{
              width: 385,
              '& .MuiInputBase-root': { height: '40px' },
            }}
            renderInput={(params) => <Input {...params} label="Institution/School Name" />}
            placeholder="Enter Institution Name"
            disableClearable
            disableListWrap
          />

          <Input
            placeholder="Enter your First Name"
            label="First Name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            className={styles.emailInput}
          />
          <Input
            placeholder="Enter your Last Name"
            label="Last Name"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            className={styles.emailInput}
          />

          <div className={styles.datePicker}>
            <ReactDatePicker
              className={styles.custom_datepicker}
              popperPlacement="bottom"
              dateFormat={DATE_FORMAT}
              popperModifiers={[
                {
                  name: 'offset',
                  options: {
                    offset: [5, 10],
                  },
                },
                {
                  name: 'preventOverflow',
                  options: {
                    rootBoundary: 'viewport',
                    tether: false,
                    altAxis: true,
                  },
                },
              ]}
              selected={dateValue}
              showYearDropdown
              showMonthDropdown
              onChange={handleDateChange}
              customInput={
                <ForwardRefInput
                  label={formatMessage({
                    id: 'email.other.method.start.date',
                  })}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <CalendarIcon className={styles.calendarIcon} color="primary" />
                      </InputAdornment>
                    ),
                  }}
                />
              }
            />
          </div>

          <div className={styles.dateContainer}>
            {timeData === undefined ? (
              <>
                <Input
                  placeholder="Start Time  (hh:mm am/pm)"
                  disabled
                  label="Start Time  (hh:mm am/pm)"
                  className={styles.emailInput}
                />
              </>
            ) : !timeData.length ? (
              <>
                <Input
                  placeholder="Start Time  (hh:mm am/pm)"
                  disabled
                  label="Start Time  (hh:mm am/pm)"
                  className={styles.emailInput}
                />

                {!isLoading && dateValue !== null && (
                  <Typography className={styles.errorText}>
                    {formatMessage({
                      id: 'clip.select.event.date.description',
                    })}
                  </Typography>
                )}
              </>
            ) : (
              <Autocomplete
                size="small"
                id="combo-box-demo"
                options={timeData}
                onChange={handleSelectTime}
                getOptionLabel={(option) => option.eventTime}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.eventId}>
                      {option.eventTime}
                    </li>
                  );
                }}
                sx={{
                  width: 370,
                  '& .MuiInputBase-root': { height: '40px' },
                }}
                ListboxProps={{ style: { maxHeight: 100 } }}
                renderInput={(params) => <Input {...params} label="Start time  (hh:mm am/pm)" />}
                placeholder="Start time  (hh:mm am/pm)"
                disableClearable
                disableListWrap
              />
            )}
          </div>

          <div>
            <Button
              onClick={() => {
                handleSubmit();
              }}
              className={styles.buttonTwo}
              disabled={!selectedValue || !firstName || !lastName || !selectedTime}
            >
              {formatMessage({ id: 'email.other.method.clip.button' })}
            </Button>
          </div>
        </Card>
      </div>

      <div className={styles.institutionContainer}>
        <Typography className={styles.clientText}>{formatMessage({ id: 'find.clips.institutions.title' })}</Typography>
        <div className={styles.imageContainer}>
          <img src={groupImg} alt="HeaderImage" className={styles.images} />
        </div>
      </div>
    </div>
  );
};
