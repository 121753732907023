import MuiButton from '@mui/material/Button';

import { useStyles } from './Button.styles';
import { ButtonProps } from './Button.types';

export const Button = ({
  children,
  size = 'medium',
  startIcon,
  endIcon,
  bold = true,
  upperCase = false,
  variant,
  color,
  ...rest
}: ButtonProps) => {
  const classes = useStyles({ bold, upperCase });
  const props = { size, classes, ...(startIcon && { startIcon }), ...(endIcon && { endIcon }), ...rest };

  return (
    <MuiButton
      color={color ?? 'primary'}
      variant={variant}
      disableRipple
      disableFocusRipple
      disableElevation
      {...props}
    >
      {children}
    </MuiButton>
  );
};
