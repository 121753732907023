import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useStyles } from './Notifications.styles';

export const Notifications = () => {
  const styles = useStyles();

  return <ToastContainer theme="colored" hideProgressBar className={styles.root} transition={Slide} icon={false} />;
};
