import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { UseStylesProps } from './Button.types';

const makeBorder = (color: string) => `solid 2px ${color}`;

export const useStyles = makeStyles<Theme, UseStylesProps>(
  ({ palette, typography: { fontWeightBold, fontWeightRegular }, spacing }) => {
    const border = makeBorder(palette.primary.main);
    const outlinedActiveColor = palette.primary.main;
    const borderRadius = spacing(0.5);
    return {
      text: {
        border: makeBorder('transparent'),
      },
      containedPrimary: {
        border,
        color: `${palette.primary.contrastText} !important`,
        backgroundColor: `${palette.primary.main} !important`,
        '&:focus:active': { borderColor: palette.action.active, backgroundColor: palette.action.active },
        '&:hover': { borderColor: palette.action.hover, backgroundColor: palette.action.hover },
        '&:disabled': {
          border: makeBorder(palette.action.disabledBackground),
          color: `${palette.primary.contrastText} !important`,
          backgroundColor: `${palette.action.disabledBackground} !important`,
        },
      },
      outlinedPrimary: {
        border: `${border} !important`,
        color: `${palette.primary.main} !important`,
        padding: `${spacing(0.5, 1.5)} !important`,
        backgroundColor: `${palette.primary.contrastText} !important`,
        '&:focus:active': {
          border: `${makeBorder(outlinedActiveColor)} !important`,
          color: `${palette.primary.contrastText} !important`,
          backgroundColor: outlinedActiveColor,
        },
        '&:hover': { border, color: `${palette.primary.main} !important`, backgroundColor: 'rgba(255, 255, 255, 0.2)' },
        '&:disabled': {
          border: `${makeBorder(palette.action.disabledBackground)} !important`,
          color: palette.action.disabledBackground,
          backgroundColor: `${palette.primary.contrastText} !important`,
        },
      },
      root: {
        fontSize: `${spacing(1.75)} !important`,
        lineHeight: spacing(2.375),
        padding: spacing(0.75, 1.5),
        borderRadius,
        fontWeight: ({ bold }) => (bold ? `${fontWeightBold} !important` : `${fontWeightRegular} !important`),
        textTransform: ({ upperCase }) => (upperCase ? 'uppercase' : 'none'),
      },
      sizeSmall: {
        fontSize: `${spacing(1.75)} !important`,
        lineHeight: spacing(1),
        padding: `${spacing(0.75, 1.5)} !important`,
        borderRadius,
      },
      sizeLarge: {
        fontSize: `${spacing(1.75)} !important`,
        lineHeight: spacing(3),
        padding: `${spacing(0.75, 1.5)} !important`,
        borderRadius,
      },
      iconSizeSmall: { '& > *:first-child': { fontSize: spacing(2) } },
      iconSizeMedium: { '& > *:first-child': { fontSize: spacing(2.375) } },
      iconSizeLarge: { '& > *:first-child': { fontSize: spacing(3) } },
    };
  },
);
