import { useSelector, useDispatch } from 'react-redux';

import { createClient } from 'api';
import { allReducerStates } from 'store/store.utils';

export const useClient = () => {
  const {
    auth: { tokens },
  } = useSelector(allReducerStates);

  const dispatch = useDispatch();

  const axiosInstance = createClient(tokens, dispatch);

  return axiosInstance;
};
