import { configureStore } from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger';
import { persistReducer, persistStore } from 'redux-persist';
import sessionStorage from 'redux-persist/lib/storage/session';

import authenticationReducer from './authenticationReducer/authenticationReducer';

const middlewares = [];

if (process.env.REACT_APP_ENV === 'development') {
  middlewares.push(createLogger());
}

export const store = configureStore({
  reducer: {
    auth: persistReducer(
      {
        key: 'auth',
        storage: sessionStorage,
        blacklist: ['isGettingUserDetails'],
      },
      authenticationReducer,
    ),
  },
  middleware: middlewares,
  devTools: process.env.REACT_APP_ENV === 'development',
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
