import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>(({ spacing }) => ({
  container: {
    marginTop: spacing(0.5),
    width: '100%',
    minWidth: spacing(5),
    '& li': {
      fontSize: `${spacing(1.625)} !important`,
      fontWeight: 'bold',
      minWidth: spacing(10),
    },
  },
}));
