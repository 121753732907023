import groupImg from 'assets/images/group-2.jpg';
import stageClipLogo from 'assets/images/stage-clip-logo.png';
import { useLocale } from 'hooks/useLocale/useLocale';
import { Button, Card, Input, Loader, Typography } from 'ui/atoms';

import { useStyles } from './Clips.styles';
import { ClipsProps } from './Clips.types';

export const Clips = ({ register, onSubmit, errors, email, isLoading }: ClipsProps) => {
  const styles = useStyles();
  const { formatMessage } = useLocale();

  return (
    <div className={styles.root}>
      <div className={styles.cardContainer}>
        <div className={styles.stageClipLogo}>
          <img src={stageClipLogo} alt="Logo" />
        </div>
        <div className={styles.content}>Find My Clip</div>
        <Card className={styles.card}>
          <form onSubmit={onSubmit}>
            <Typography className={styles.emailTitle}>{formatMessage({ id: 'find.clips.email.title' })}</Typography>
            <Input
              placeholder="Enter your institution email"
              label="Institution email"
              name="email"
              register={register}
              type="email"
              className={styles.emailInput}
              error={!!errors?.email}
              helperText={errors?.email?.message}
            />
            <div>
              {isLoading ? (
                <>
                  <Loader className={styles.loader} />
                  <Button disabled className={styles.button} type="submit">
                    {formatMessage({ id: 'find.clips.email.send.button' })}
                  </Button>
                </>
              ) : (
                <Button disabled={email === '' || !!errors?.email?.message} className={styles.button} type="submit">
                  {formatMessage({ id: 'find.clips.email.send.button' })}
                </Button>
              )}
            </div>
          </form>
        </Card>
      </div>

      <div className={styles.institutionContainer}>
        <Typography className={styles.clientText}>{formatMessage({ id: 'find.clips.institutions.title' })}</Typography>
        <div className={styles.imageContainer}>
          <img src={groupImg} alt="HeaderImage" className={styles.images} />
        </div>
      </div>
    </div>
  );
};
