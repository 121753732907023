export enum AppRoute {
  clips = '/',
  checkEmail = '/check-email',
  sentCustomEmail = '/sent-custom-email',
  clipFindOtherMethod = '/clip-find',
  createTicket = '/create-ticket',
  clipNotAvailable = '/clip-not-available',
  clipSentSuccessful = '/clip-sent',
  singleEventSuccess = '/single-event-success',
  multiEventSuccess = '/multi-event-success',
  multiEventSent = '/multi-event-sent',
  clipInProgress = '/clip-in-progress',
  clipWaitingAssets = '/clip-waiting-assets',
  raiseTicket = '/raise-ticket',
  notFound = '/notfound',
  clipDeleted = '/clip-deleted',
  clipUserExist = '/clip-user-exists',
  noClipUserExist = '/no-clip-user-exists',
  clipCloseSuccess = '/clip-close-success',
  noNameList = '/no-name-list',
}
