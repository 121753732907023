import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { getValue } from './input.utils';

export type UseStylesProps = {
  success?: boolean;
  boldLabel?: boolean;
  required?: boolean;
  warning?: boolean;
  filter?: boolean;
};

export const useStyles = makeStyles<Theme, UseStylesProps>(({ palette, spacing, breakpoints }) => ({
  root: {
    width: '100%',
    fontSize: spacing(1.625),
    lineHeight: spacing(3),
    '& .Mui-disabled .MuiOutlinedInput-notchedOutline': {
      borderColor: palette.gray.dark,
    },
    '& .MuiInputLabel-outlined': {
      pointerEvents: 'visible',
    },
    '& .MuiFormLabel-root': {
      transform: 'none',
      transition: 'none',
      transformOrigin: 'unset',
      position: 'initial',
      lineHeight: ({ filter }) => filter && spacing(3),
      marginBottom: spacing(1),
      fontSize: spacing(1.625),
      color: palette.black.main,
      fontWeight: ({ boldLabel }) => getValue({ condition: boldLabel, value: 'bold', defaultValue: 'normal' }),
      '& .MuiFormLabel-asterisk': {
        color: palette.primary.main,
        display: ({ required }) => !required && 'none',
      },
      [breakpoints.down('sm')]: {
        fontSize: spacing(1.625),
        marginBottom: spacing(0.5),
      },
    },
    '& label.Mui-focused': {
      color: palette.black.main,
    },
    '& .MuiOutlinedInput-root': {
      fontSize: spacing(1.625),
      [breakpoints.down('sm')]: {
        fontSize: spacing(1.625),
      },
      lineHeight: ({ filter }) => (filter ? spacing(3) : spacing(3.5)),
      marginRight: ({ filter }) => filter && spacing(2),
      borderRadius: spacing(0.75),
      '& .MuiOutlinedInput-input': {
        height: spacing(3),
        fontSize: `${spacing(1.625)} !important`,
        padding: `${spacing(0.75, 1.5)} !important`,
        [breakpoints.down('sm')]: {
          padding: spacing(1.25),
          height: spacing(2.75),
        },
      },
      '& .MuiOutlinedInput-inputMultiline': {
        padding: 0,
        height: 'auto',
      },
      '& fieldset': {
        borderColor: ({ success, warning }) =>
          `${warning ? palette.orange.main : success ? palette.success.main : palette.gray.main}`,
        borderWidth: spacing(0.25),
        '& legend': {
          width: 0,
        },
      },
      '& input': {
        '&::placeholder': {
          color: palette.gray.dark,
        },
        minWidth: ({ filter }) => filter && spacing(33),
      },
      '&:not(.Mui-error):not(.Mui-disabled)': {
        '&:hover fieldset': {
          borderColor: ({ success, warning }) =>
            `${warning ? palette.orange.main : success ? palette.success.main : palette.gray.main}`,
        },
        '&.Mui-focused fieldset': {
          borderColor: ({ success, warning }) =>
            `${warning ? palette.orange.main : success ? palette.green.main : palette.green.main}`,
        },
      },
      '& .Mui-disabled': {
        backgroundColor: palette.gray.main,
      },
    },
    '& .MuiFormHelperText-root': {
      margin: spacing(1, 0, 0),
      fontStyle: 'normal',
      fontSize: spacing(1.625),
      lineHeight: 'inherit',
      display: 'flex',
      alignItems: 'center',
      '&:not(.Mui-error)': {
        color: ({ success, warning }) =>
          `${warning ? palette.orange.main : success ? palette.success.main : palette.gray.dark}`,
        '& svg': {
          color: palette.orange.main,
        },
      },
      '& svg': {
        color: 'red',
      },
    },
  },
  checkIcon: {
    color: palette.success.main,
  },
}));
