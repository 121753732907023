import { createSlice } from '@reduxjs/toolkit';

import { AuthenticationState } from './authenticationReducer.types';

export const authenticationInitialState: AuthenticationState = {
  isAuthorized: false,
  tokens: {
    token: '',
    xsrfToken: '',
  },
};

export const authentication = createSlice({
  name: 'authentication',
  initialState: authenticationInitialState,
  reducers: {
    onLoginSuccess: (state, { payload }) => {
      state.isAuthorized = true;
      state.tokens = payload;
    },
    onLogOutAction: (state) => {
      state.isAuthorized = false;
      state.tokens = authenticationInitialState.tokens;
    },
  },
  extraReducers: {},
});

export const { onLoginSuccess, onLogOutAction } = authentication.actions;

// eslint-disable-next-line import/no-default-export
export default authentication.reducer;
