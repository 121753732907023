import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>(({ palette: { primary }, spacing }) => ({
  typing: {
    position: 'relative',
    display: 'flex',
    gap: spacing(0.5),
    alignItems: 'center',
    '& span': {
      content: "''",
      animation: '$blink 1.5s infinite',
      animationFillMode: 'both',
      height: spacing(1.25),
      width: spacing(1.25),
      background: primary.main,
      position: 'relative',
      left: 0,
      top: 0,
      borderRadius: '50%',
      '&:nth-child(2)': {
        animationDelay: '.2s',
      },
      '&:nth-child(3)': {
        animationDelay: '.4s',
      },
    },
  },
  '@keyframes blink': {
    '0%': {
      opacity: '.1',
    },
    '20%': {
      opacity: '1',
    },
    '100%': {
      opacity: '.1',
    },
  },
}));
