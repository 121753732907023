import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>(({ breakpoints, spacing, palette: { black, primary, gray } }) => ({
  mainWrapper: {
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'center',
  },
  itemsPerPage: {
    width: 'unset',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& label': {
      marginBottom: 0,
      marginRight: spacing(2),
      fontSize: `${spacing(2)} !important`,
    },
    '& .react-select__control': {
      minHeight: spacing(4),
      borderWidth: '2px',
      borderColor: gray.main,
    },
    '& .react-select__value-container': {
      padding: `${spacing(0.125, 3.75, 0.125, 2)} !important`,
      minHeight: spacing(4),
      fontSize: `${spacing(2)} !important`,
    },
    '& .react-select__indicator svg': {
      marginRight: 0,
    },
  },
  pagination: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: spacing(2.25),
    padding: spacing(3, 0),

    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },

    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },

    '& .MuiFormControl-root': {
      width: spacing(7.5),
    },

    '& .MuiInputBase-input.MuiOutlinedInput-input': {
      padding: spacing(0.75, 1.5),
      fontSize: `${spacing(2)} !important`,
    },

    [breakpoints.up('lg')]: {
      justifyContent: 'flex-end',
    },
  },
  slash: {
    padding: spacing(0, 0.75, 0, 1),
    fontSize: `${spacing(2)} !important`,
  },
  count: {
    fontSize: `${spacing(2)} !important`,
  },
  paginationControl: {
    cursor: 'pointer',
    padding: spacing(0, 1.5),
    display: 'flex',
    alignItems: 'center',
    fontSize: `${spacing(2)} !important`,
    transition: 'color .3s ease-out',
    color: black.main,
    '&:hover': {
      color: primary.main,
    },
  },
  isDisabled: {
    pointerEvents: 'none',
    color: gray.main,
  },
}));
