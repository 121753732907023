import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { TOPBAR_HEIGHT } from 'app/layout/dashboard/Dashboard.utils';
import { yellow } from 'theme/palette';

export const useStyles = makeStyles<Theme>(
  ({ spacing, palette: { white, green }, typography: { fontWeightBold }, breakpoints }) => ({
    content: {
      display: 'flex',
      justifyContent: 'center',
      fontFamily: 'Open Sans',
      fontSize: spacing(6),
      fontWeight: fontWeightBold,
      marginBottom: spacing(5),
    },
    container: {
      backgroundColor: white.dark,
    },
    emailTitle: {
      fontWeight: `700 !important`,
      fontSize: `${spacing(3.5)} !important`,
      justifyContent: 'center',
      display: 'flex',
      fontFamily: `Open Sans !important`,
      marginBottom: `${spacing(0.25)} !important`,
      lineHeight: spacing(5),
    },
    differentEmail: {
      fontWeight: `700 !important`,
      fontSize: `${spacing(2)} !important`,
      justifyContent: 'center',
      display: 'flex',
      fontFamily: `Roboto !important`,
      marginBottom: `${spacing(0.25)} !important`,
      lineHeight: spacing(0),
      textAlign: 'center',
      marginTop: `${spacing(2)} !important`,
    },
    linkTicket: {
      fontWeight: `${fontWeightBold} !important`,
      fontSize: `${spacing(2)} !important`,
      justifyContent: 'center',
      display: 'flex',
      fontFamily: `Roboto !important`,

      lineHeight: spacing(5),
      textAlign: 'center',
      color: green.main,
      marginRight: '-12rem',
      marginTop: '-2.2rem',
    },
    clipLink: {
      textAlign: 'center',
      color: green.main,
    },
    emailDescription: {
      fontSize: `${spacing(1.9)} !important`,
      justifyContent: 'center',
      fontFamily: `Roboto !important`,
      textAlign: 'center',
      marginBottom: `${spacing(1)} !important`,
      marginTop: `${spacing(1)} !important`,
      lineHeight: `${spacing(3)} !important`,
      // flexDirection: 'row-reverse',
      letterSpacing: `${spacing(0.02)} !important`,
      color: '#0000008a',
    },
    overrideColor: {
      color: 'initial',
    },
    card: {
      margin: 'auto',
      width: spacing(50),
      // height: spacing(36),
      backgroundColor: white.light,
      borderRadius: spacing(1.25),
      padding: spacing(2),
    },
    button: {
      width: '100%',
      backgroundColor: `${green.main} !important`,
      color: `${white.main} !important`,
      marginTop: `${spacing(4)} !important`,
    },
    clientText: {
      fontSize: `${spacing(4)} !important`,
      fontStyle: `Open Sans !important`,
      textAlign: 'center',
      fontWeight: `${fontWeightBold}  !important`,
      color: '#595959',
      marginTop: `${spacing(3)} !important`,
    },
    imageContainer: {
      display: 'flex',
      padding: spacing(4),
      justifyContent: 'center',
      HeaderImage: {
        padding: spacing(4),
      },
      margin: spacing(1, 4, 0, 4),
    },
    images: {
      margin: spacing(0, 6, 0, 6),
    },
    root: {
      height: `calc(100vh - ${TOPBAR_HEIGHT}px)`,
      // height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    cardContainer: {
      [breakpoints.up('md')]: {
        marginTop: spacing(8),
      },
    },
    stageClipLogo: {
      height: '10%',
      [breakpoints.up('md')]: {
        display: 'none',
      },
      margin: spacing(1, 0, 1, 2.5),
      display: 'flex',
      justifyContent: 'center',
      marginBottom: `${spacing(1)} !important`,
    },
    institutionContainer: {
      [breakpoints.down('md')]: {
        display: 'none',
      },
      marginBottom: `${spacing(5)} !important`,
    },
    icon: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: spacing(1),
      color: `${yellow} !important`,
      //   width: `${spacing(6)} !important`,
      //   height: `${spacing(1)} !important`,
    },
    systemStatus: {
      fontWeight: `${fontWeightBold} !important`,
      fontSize: `${spacing(2)} !important`,
      justifyContent: 'center',
      display: 'flex',
      fontFamily: `Roboto !important`,
      marginBottom: `${spacing(0.25)} !important`,
      lineHeight: spacing(3),
    },
  }),
);
