import groupImg from 'assets/images/group-2.jpg';
import stageClipLogo from 'assets/images/stage-clip-logo.png';
import { useLocale } from 'hooks/useLocale/useLocale';
import { Button, Card, Typography } from 'ui/atoms';

import { useStyles } from './AddTicket.style';

export const AddTicket = () => {
  const styles = useStyles();
  const { formatMessage } = useLocale();

  return (
    <div className={styles.root}>
      <div className={styles.cardContainer}>
        <div className={styles.stageClipLogo}>
          <img src={stageClipLogo} alt="Logo" />
        </div>
        <div className={styles.content}>Find My Clip</div>
        <Card className={styles.card}>
          <Typography className={styles.emailTitle}>{formatMessage({ id: 'ticket.add.title' })}</Typography>

          <div className={styles.emailDescription}>
            <Typography>{formatMessage({ id: 'ticket.add.description' })}</Typography>
            <Typography>
              {formatMessage({ id: 'ticket.add.description.two' })} <span className={styles.overrideColor}> 💌 </span>
            </Typography>
          </div>
          <div>
            <Button className={styles.button}>{formatMessage({ id: 'ticket.add.button' })}</Button>
          </div>
        </Card>
      </div>

      <div className={styles.institutionContainer}>
        <Typography className={styles.clientText}>{formatMessage({ id: 'find.clips.institutions.title' })}</Typography>
        <div className={styles.imageContainer}>
          <img src={groupImg} alt="HeaderImage" className={styles.images} />
        </div>
      </div>
    </div>
  );
};
