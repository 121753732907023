import ReactDOM from 'react-dom/client';

import 'assets/styles/global.css';
import { AppProviders } from 'providers/AppProviders';

import { App } from './app/App';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <AppProviders>
    <App />
  </AppProviders>,
);
