// import { ReactComponent as CloseIcon } from 'assets/icons/close-x.svg';
// import { ReactComponent as MenuIcon } from 'assets/icons/menu-icon.svg';
import { useNavigate } from 'react-router-dom';

import MessageMatrixLogo from 'assets/images/stage-clip-logo.png';
import MessageMatrixLogoMobile from 'assets/images/stage-clip-logo.png';

import { useStyles } from './TopBar.styles';
import { TopBarProps } from './TopBar.types';

export const TopBar = ({ isSidebarDisplayed }: TopBarProps) => {
  // it's necessary because we always want to display the sidebar on desktop devices but on tablet and mobile on-demand

  const styles = useStyles({ isSidebarDisplayed });
  const navigate = useNavigate();

  // const photo = '';
  // const title = 'test user';

  return (
    <div className={styles.topbar}>
      {/* <div
        className={styles.hamburgerMenu}
        onClick={() => setIsSidebarDisplayed((previousShowSidebar) => !previousShowSidebar)}
        role="button"
        aria-label="Menu"
        tabIndex={0}
      >
        {isSidebarDisplayed ? <CloseIcon /> : <MenuIcon />}
      </div> */}
      <img src={MessageMatrixLogo} alt="Logo" className={styles.MessageMatrixLogo} onClick={() => navigate('/')} />
      <img src={MessageMatrixLogoMobile} alt="Logo" className={styles.MessageMatrixLogoMobile} />
    </div>
  );
};
