import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { UseStylesProps } from './Badge.types';

export const useStyles = makeStyles<Theme, UseStylesProps>(
  ({ palette: { background, success, error, warning }, spacing }) => {
    return {
      root: {
        height: 'fit-content',
        '& .MuiBadge-badge': {
          backgroundColor: ({ status }) =>
            status === 'Active' ? success.main : status === 'Offline' ? error.main : warning.light,
          color: ({ status }) =>
            status === 'Active' ? success.main : status === 'Offline' ? error.main : warning.light,
          boxShadow: `0 0 0 ${spacing(0.25)} ${background.paper}`,
          '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            border: `${spacing(0.125)} solid currentColor`,
            content: '""',
          },
        },
      },
    };
  },
);
